<template>
  <div class="PaiementPrix" >
    <validationCartouche
      :hasCedantValidated="paiementPrix.has_cedant_validated"
      :hasRepreneurValidated="paiementPrix.has_repreneur_validated"
    />


    <div class="container-fluid">

      <macroStepper
        :step="3"
      />


      <h1 class="gavc-h1 text-center">3. Modalités de paiement du prix </h1>

      <div class="mt-4 mb-4 d-flex justify-content-between">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="$router.push(`/audit/entreprise-cedee/${paiementPrix.audit.id}`)"
        >
          <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente
        </button>
        <button
          v-if="paiementPrix.has_repreneur_validated && paiementPrix.has_cedant_validated"
          class="gavc-btn"
          @click="goToDashboard"
        >
          <i class="gavc-icon gavc-icon-arrow-right"></i> Étape validée - Retour au tableau de bord
        </button>
      </div>


      <!-- PARTIE COMMENTAIRE -->
      <div v-if="userGroupContains(['REPRENEUR'])">
        <div class="gavc-field">
          <label class="gavc-label" for="paiementPrix-repreneur_comment-input">
            <strong class="gavc-text-tory">En tant que repreneur</strong>, vous pouvez laisser un commentaire au cédant via le champ ci-dessous
          </label>
          <textarea
            class="gavc-textarea"
            id="paiementPrix-repreneur_comment-input"
            maxlength="1000"
            rows="3"
            @change="modifyPaiementPrix"
            :disabled="hasValidatedPaiementPrix"
            placeholder="Je laisse mon commentaire au cédant"
            type="text"
            v-model="paiementPrix.repreneur_comment">
          </textarea>
        </div>
      </div>

      <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="paiementPrix.repreneur_comment">
        <strong> Le repreneur vous a laissé un commentaire </strong> <br>
        <span style="white-space: pre-line"> {{paiementPrix.repreneur_comment}} </span>
      </div>

      <div v-if="userGroupContains(['CEDANT'])">
        <div class="gavc-field">
          <label class="gavc-label" for="paiementPrix-cedant_comment-input">
            <strong class="gavc-text-green">En tant que cédant</strong>, vous pouvez laisser un commentaire au repreneur via le champ ci-dessous
          </label>
          <textarea
            class="gavc-textarea"
            id="paiementPrix-cedant_comment-input"
            maxlength="1000"
            rows="3"
            @change="modifyPaiementPrix"
            :disabled="hasValidatedPaiementPrix"
            placeholder="Je laisse mon commentaire au repreneur"
            type="text"
            v-model="paiementPrix.cedant_comment">
          </textarea>
        </div>
      </div>

      <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="paiementPrix.cedant_comment">
        <strong> Le cédant vous a laissé un commentaire </strong> <br>
        <span style="white-space: pre-line"> {{paiementPrix.cedant_comment}} </span>
      </div>


      <p class="gavc-p">
        Durant cette phase, <strong>les deux parties peuvent compléter toutes les informations</strong>. Ainsi, il vous est nécessaire de :
        <ul>
          <li><strong>Renseigner les informations que vous souhaitez compléter et charger les documents</strong> correspondants</li>
          <li><strong>Vérifier les informations renseignées</strong> et <strong>valider les documents chargés</strong></li>
          <li><strong>Valider cette phase</strong> (cette validation signifie que vous validez toutes les informations renseignées et documents chargés</li>
        </ul>
      </p>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.organisation-prix-cession-collapse>
        Organisation du prix de cession
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="organisation-prix-cession-collapse" role="tabpanel">
        <p class="gavc-p">
          Le prix de cession défini au niveau de l'étape des pourparlers est de 
          {{formatThousandSeparatorNumber(Number(paiementPrix.audit.pourparler_price))}} €
        </p>
        <p class="gavc-label mt-3">
          Le paiement se fait
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="paiementPrix.is_paye_one_shot-input-1"
                     name="paiementPrix.is_paye_one_shot-input-1" v-model="paiementPrix.is_paye_one_shot"
                     :disabled="hasValidatedPaiementPrix">
              <label class="gavc-label" for="paiementPrix.is_paye_one_shot-input-1">
                En une seule fois
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="paiementPrix.is_paye_one_shot-input-2"
                     name="paiementPrix.is_paye_one_shot-input-2" v-model="paiementPrix.is_paye_one_shot"
                     :disabled="hasValidatedPaiementPrix">
              <label class="gavc-label" for="paiementPrix.is_paye_one_shot-input-2">
                En fractionné
              </label>
            </div>
          </div>
        </div>

        <div v-if="paiementPrix.is_paye_one_shot">
          <div
             class="gavc-field mt-3"
             v-bind:class="{ 'is-error': $v.paiementPrix.montant_total_one_shot.$dirty ? $v.paiementPrix.montant_total_one_shot.$error : null  }">
             <label class="gavc-label" for="paiementPrix.montant_total_one_shot-input">
                 Montant total
                 <span class="gavc-required"> * </span>
             </label>
            <div class="gavc-field gavc-field--icon">
              <numberMoneyInput
                id="paiementPrix.montant_total_one_shot-input"
                :maxLength="'10'"
                :maskedOptions="maskedMontantOptions"
                :value="paiementPrix.montant_total_one_shot"
                @value-changed="paiementPrix.montant_total_one_shot = $event"
                @input="$v.paiementPrix.montant_total_one_shot.$touch()"
                :disabled="hasValidatedPaiementPrix"
              />
              <i class="gavc-icon gavc-icon-euro" ></i>
            </div>
            <inputErrorValidationMessage 
              v-if="paiementPrix.montant_total_one_shot && Number(paiementPrix.montant_total_one_shot) !== Number(paiementPrix.audit.pourparler_price)"
              :message="`Attention, veuillez renseigner un montant égal au prix établi au sein de l'étape des pourparlers (${formatThousandSeparatorNumber(Number(paiementPrix.audit.pourparler_price))} €)`"
            />
          </div>
        </div>

        <div v-if="paiementPrix.is_paye_one_shot === false">
          <p class="gavc-p gavc-text-tory">
            Les paiements fractionnés sont réalisés <strong><u>par virement</u></strong> dans le modèle d'acte de cession généré automatiquement après cette étape
          </p>
          <p class="gavc-label mt-3">
            Le paiement en fractionné se fait
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="'SOMMES_FIXES'" type="radio" id="paiementPrix.fractionne_type-input-1"
                       name="paiementPrix.fractionne_type-input-1" v-model="paiementPrix.fractionne_type"
                       :disabled="hasValidatedPaiementPrix">
                <label class="gavc-label" for="paiementPrix.fractionne_type-input-1">
                  A sommes fixes
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="'SOMMES_VARIABLES'" type="radio" id="paiementPrix.fractionne_type-input-2"
                       name="paiementPrix.fractionne_type-input-2" v-model="paiementPrix.fractionne_type"
                       :disabled="hasValidatedPaiementPrix">
                <label class="gavc-label" for="paiementPrix.fractionne_type-input-2">
                  A sommes variables
                </label>
              </div>
            </div>
          </div>

          <div v-if="paiementPrix.fractionne_type === 'SOMMES_FIXES'">
            <div
               class="gavc-field mt-4"
               v-bind:class="{ 'is-error': $v.paiementPrix.montant_sommes_fixes.$dirty ? $v.paiementPrix.montant_sommes_fixes.$error : null  }">
               <label class="gavc-label" for="paiementPrix.montant_sommes_fixes-input">
                   Le montant de chaque somme est de
                   <span class="gavc-required"> * </span>
               </label>
              <div class="gavc-field gavc-field--icon">
                <numberMoneyInput
                  id="paiementPrix.montant_sommes_fixes-input"
                  :maxLength="'10'"
                  :maskedOptions="maskedMontantOptions"
                  :value="paiementPrix.montant_sommes_fixes"
                  @value-changed="paiementPrix.montant_sommes_fixes = $event"
                  @input="$v.paiementPrix.montant_sommes_fixes.$touch()"
                  :disabled="hasValidatedPaiementPrix"
                />
                <i class="gavc-icon gavc-icon-euro" ></i>
              </div>
            </div>


            <div class="mt-4">
              <strong>
                Veuillez renseigner les dates auxquelles les versements seront effectués :
              </strong>
              <div
                class="w-50 mt-2 p-2 gavc-bg-bizarre"
                style="border-radius: 10px;"
                v-for="dateEcheancePrixSommesFixes in paiementPrix.date_echeances_sommes_fixes" :key="dateEcheancePrixSommesFixes.id"
              >
                Date : {{$dayjs(dateEcheancePrixSommesFixes.date).format('DD/MM/YYYY')}}
                <button
                  class="ml-4 float-right"
                  style="background-color: transparent !important; border: 0 !important;"
                  @click="deleteAuditSectionInformation(dateEcheancePrixSommesFixes.id, 'dateEcheancePrixSommesFixes')">
                  <i class="gavc-icon gavc-icon-cross"></i>
                </button>
              </div>
              <form class="form-inline mt-4" v-on:submit.prevent="addDateEcheancePrixSommesFixes()">
                <input
                  @input="$v.newDateEcheancePrixSommesFixesDate.$touch()"
                  class="gavc-input gavc-input--icon gavc-input--calendar"
                  id="newDateEcheancePrixSommesFixesDate-input"
                  type="date"
                  v-model="newDateEcheancePrixSommesFixesDate"
                  :disabled="hasValidatedPaiementPrix"
                >
                <button
                  class="gavc-btn"
                  :disabled="$v.newDateEcheancePrixSommesFixesDate.$invalid"
                  type="submit"
                >
                  Ajouter une nouvelle date
                </button>
              </form>
            </div>

            <inputErrorValidationMessage 
              v-if="paiementPrix.montant_sommes_fixes && paiementPrix.date_echeances_sommes_fixes.length * Number(paiementPrix.montant_sommes_fixes) !== Number(paiementPrix.audit.pourparler_price)"
              :message="`Attention, la somme des montants doit être égale au prix établi au sein de l'étape des pourparlers (${formatThousandSeparatorNumber(Number(paiementPrix.audit.pourparler_price))} €)`"
            />

          </div>


          <div v-if="paiementPrix.fractionne_type === 'SOMMES_VARIABLES'">
            <div class="mt-4">
              <strong>
                Veuillez renseigner les dates et montant des versements :
              </strong>
              <div
                class="w-50 mt-2 p-2 gavc-bg-bizarre"
                style="border-radius: 10px;"
                v-for="dateEcheancePrixSommesVariables in paiementPrix.date_echeances_sommes_variables" :key="dateEcheancePrixSommesVariables.id"
              >
                Date : {{$dayjs(dateEcheancePrixSommesVariables.date).format('DD/MM/YYYY')}} / Montant : {{formatThousandSeparatorNumber(Number(dateEcheancePrixSommesVariables.montant).toFixed(0))}} €
                <button
                  class="ml-4 float-right"
                  style="background-color: transparent !important; border: 0 !important;"
                  @click="deleteAuditSectionInformation(dateEcheancePrixSommesVariables.id, 'dateEcheancePrixSommesVariables')">
                  <i class="gavc-icon gavc-icon-cross"></i>
                </button>
              </div>
              <form class="form-inline mt-4" v-on:submit.prevent="addDateEcheancePrixSommesVariables()">
                <input
                  @input="$v.newDateEcheancePrixSommesVariablesDate.$touch()"
                  class="gavc-input gavc-input--icon gavc-input--calendar"
                  id="newDateEcheancePrixSommesVariables-input"
                  type="date"
                  v-model="newDateEcheancePrixSommesVariablesDate"
                  :disabled="hasValidatedPaiementPrix"
                >
                <numberMoneyInput
                  id="newDateEcheancePrixSommesVariablesMontant-input"
                  :maxLength="'10'"
                  :maskedOptions="maskedMontantOptions"
                  :value="newDateEcheancePrixSommesVariablesMontant"
                  @value-changed="newDateEcheancePrixSommesVariablesMontant = $event"
                  @input="$v.newDateEcheancePrixSommesVariablesMontant.$touch()"
                  :disabled="hasValidatedPaiementPrix"
                />
                <button
                  class="gavc-btn"
                  :disabled="$v.newDateEcheancePrixSommesVariablesDate.$invalid || $v.newDateEcheancePrixSommesVariablesMontant.$învalid"
                  type="submit"
                >
                  Ajouter une nouvelle échéance
                </button>
              </form>
            </div>
            <inputErrorValidationMessage 
              v-if="paiementPrix.date_echeances_sommes_variables.reduce((sum, item) => Number(item.montant) + sum, 0) !== Number(paiementPrix.audit.pourparler_price)"
              :message="`Attention, la somme des montants doit être égale au prix établi au sein de l'étape des pourparlers (${formatThousandSeparatorNumber(Number(paiementPrix.audit.pourparler_price))} €)`"
            />
          </div>
        </div>
      </b-collapse>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.clause-interessement-collapse>
        Clause d’intéressement sur les performances futures
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="clause-interessement-collapse" role="tabpanel">
        <p class="mt-3">
          Il s'agit de la clause de « earn out » elle permet le versement d’un complément du prix de cession
          relatif aux performances futures ou à la réalisation d’événements identifiés
        </p>


        <p class="gavc-label mt-3">
          Souhaitez-vous intégrer une clause de complément de prix de cession en cas de performances de l’entreprise future ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="paiementPrix.is_clause_interessement_performances_futures-input-1"
                     name="paiementPrix.is_clause_interessement_performances_futures-input-1"
                     v-model="paiementPrix.is_clause_interessement_performances_futures"
                     :disabled="hasValidatedPaiementPrix">
              <label class="gavc-label" for="paiementPrix.is_clause_interessement_performances_futures-input-1">
                Oui
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="paiementPrix.is_clause_interessement_performances_futures-input-2"
                     name="paiementPrix.is_clause_interessement_performances_futures-input-2"
                     v-model="paiementPrix.is_clause_interessement_performances_futures"
                     :disabled="hasValidatedPaiementPrix">
              <label class="gavc-label" for="paiementPrix.is_clause_interessement_performances_futures-input-2">
                Non
              </label>
            </div>
          </div>
        </div>

        <div v-if="paiementPrix.is_clause_interessement_performances_futures">
          <p class="gavc-chapo">
            Remplissez les champs ci-dessous pour remplir la clause suivante :
          </p>
          <p style="font-style: italic;">
            "Durant une période de
            <span v-if="paiementPrix.clause_interessement_nb_months" style="background-color : yellow;">
              {{paiementPrix.clause_interessement_nb_months}} mois
            </span>
            <span v-else style="background-color : yellow;">
              NOMBRE DE MOIS
            </span>
            à compter de la cession,
            l’acquéreur s’engage à verser un complément de prix au vendeur correspondant à
            <span v-if="paiementPrix.clause_interessement_percent_ratio_comptable" style="background-color : yellow;">
              {{paiementPrix.clause_interessement_percent_ratio_comptable}} %
            </span>
            <span v-else style="background-color : yellow;">
              POURCENTAGE
            </span>
            de
            <span v-if="paiementPrix.clause_interessement_ratio_comptable_chosen" style="background-color : yellow;">
              {{paiementPrix.clause_interessement_ratio_comptable_chosen}}
            </span>
            <span v-else style="background-color : yellow;">
              RATIO COMPTABLE CHOISI
            </span>
            de l’exercice de la cession, des exercices couverts par la période indiquée et
            proportionnellement du dernier exercice comptable concernée (pro rata temporis) si :
            <span v-if="paiementPrix.clause_interessement_liste_conditions" style="background-color : yellow;">
              {{paiementPrix.clause_interessement_liste_conditions}}
            </span>
            <span v-else style="background-color : yellow;">
              CONDITIONS DE VERSEMENT
            </span>
          </p>

          <div class="row">
            <div class="col-12 col-lg-4">
              <div
                 class="gavc-field"
                 v-bind:class="{ 'is-error': $v.paiementPrix.clause_interessement_nb_months.$dirty ? $v.paiementPrix.clause_interessement_nb_months.$error : null  }">
                 <label class="gavc-label" for="paiementPrix.clause_interessement_nb_months-input">
                    Clause : Nombre de mois
                 </label>
                <div class="gavc-field">
                  <input class="gavc-input"
                     @input="$v.paiementPrix.clause_interessement_nb_months.$touch()"
                     id="paiementPrix.clause_interessement_nb_months-input"
                     placeholder="0"
                     type="number"
                     v-model="paiementPrix.clause_interessement_nb_months"
                     @change="modifyPaiementPrix"
                     :disabled="hasValidatedPaiementPrix"
                    >
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div
                 class="gavc-field"
                 v-bind:class="{ 'is-error': $v.paiementPrix.clause_interessement_percent_ratio_comptable.$dirty ? $v.paiementPrix.clause_interessement_percent_ratio_comptable.$error : null  }">
                 <label class="gavc-label" for="paiementPrix.clause_interessement_percent_ratio_comptable-input">
                    Clause : Pourcentage
                 </label>
                <div class="gavc-field">
                  <input class="gavc-input"
                     @input="$v.paiementPrix.clause_interessement_percent_ratio_comptable.$touch()"
                     id="paiementPrix.clause_interessement_percent_ratio_comptable-input"
                     placeholder="0"
                     type="number"
                     v-model="paiementPrix.clause_interessement_percent_ratio_comptable"
                     @change="modifyPaiementPrix"
                     :disabled="hasValidatedPaiementPrix"
                    >
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div
                class="gavc-field"
                v-bind:class="{ 'is-error': $v.paiementPrix.clause_interessement_ratio_comptable_chosen.$dirty ? $v.paiementPrix.clause_interessement_ratio_comptable_chosen.$error : null  }">
                <label class="gavc-label" for="paiementPrix-clause_interessement_ratio_comptable_chosen-group">
                  Clause : Ratio comptable choisi
                  <span class="gavc-required"> * </span>
                </label>
                <input
                  class="gavc-input"
                  id="paiementPrix-clause_interessement_ratio_comptable_chosen-group"
                  maxlength="250"
                  @input="$v.paiementPrix.clause_interessement_ratio_comptable_chosen.$touch()"
                  type="text"
                  placeholder="Ratio comptable (résultat d'exploitation, chiffre d'affaires, etc.)"
                  v-model="paiementPrix.clause_interessement_ratio_comptable_chosen"
                  @change="modifyPaiementPrix"
                  :disabled="hasValidatedPaiementPrix"
                >
              </div>
            </div>
          </div>

          <div class="gavc-field mt-2">
            <label class="gavc-label" for="paiementPrix-clause_interessement_liste_conditions-input">
              Clause : Conditions de versement
            </label>
            <textarea
              class="gavc-textarea"
              id="elementsSociauxActiviteCedee-clause_interessement_liste_conditions-input"
              maxlength="1500"
              rows="5"
              placeholder="Liste de conditions : ex, le CA a augmenté de plus de 10% depuis l’exercice antérieur à la cession, le résultat d’exploitation a augmenté de plus de 20% lors de l’exercice suivant celui de la cession, etc."
              type="text"
              v-model="paiementPrix.clause_interessement_liste_conditions"
              @change="modifyPaiementPrix"
              :disabled="hasValidatedPaiementPrix"
            >
            </textarea>
          </div>
        </div>
      </b-collapse>



      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.modalite-versement-collapse>
        Modalités de versement du prix
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="modalite-versement-collapse" role="tabpanel">
        <p class="gavc-label mt-3">
          Le <strong>virement</strong> est-il une des modalités de versement du prix ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="paiementPrix.is_virement-input-1"
                     name="paiementPrix.is_virement-input-1" v-model="paiementPrix.is_virement"
                     :disabled="hasValidatedPaiementPrix">
              <label class="gavc-label" for="paiementPrix.is_virement-input-1">
                Oui
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="paiementPrix.is_virement-input-2"
                     name="paiementPrix.is_virement-input-2" v-model="paiementPrix.is_virement"
                     :disabled="hasValidatedPaiementPrix">
              <label class="gavc-label" for="paiementPrix.is_virement-input-2">
                Non
              </label>
            </div>
          </div>
        </div>

        <div v-if="paiementPrix.is_virement">

          <div
             class="gavc-field mt-2"
             v-bind:class="{ 'is-error': $v.paiementPrix.montant_virement.$dirty ? $v.paiementPrix.montant_virement.$error : null  }">
             <label class="gavc-label" for="paiementPrix.montant_virement-input">
                 Montant
             </label>
            <div class="gavc-field gavc-field--icon">
              <numberMoneyInput
                id="paiementPrix.montant_virement-input"
                :maxLength="'10'"
                :maskedOptions="maskedMontantOptions"
                :value="paiementPrix.montant_virement"
                @value-changed="paiementPrix.montant_virement = $event"
                @input="$v.paiementPrix.montant_virement.$touch()"
                :disabled="hasValidatedPaiementPrix"
              />
              <i class="gavc-icon gavc-icon-euro" ></i>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6">
              <div
                class="gavc-field mt-3"
                v-bind:class="{ 'is-error': $v.paiementPrix.iban_vendeur.$dirty ? $v.paiementPrix.iban_vendeur.$invalid : null  }">
                <label class="gavc-label" for="paiementPrix-iban_vendeur-group">
                  Iban du vendeur
                  <span class="gavc-required"> * </span>
                </label>

                <b-form-input
                  v-model.lazy="maskedIbanVendeur" type="text"
                  v-mask="'NNNN NNNN NNNN NNNN NNNN NNNN NNN'"
                  maxlength="50"
                  placeholder="Iban"
                  class="gavc-bootstrap-input"
                  @change="modifyPaiementPrix"
                  id="iban-input"
                  @input="$v.paiementPrix.iban_vendeur.$touch()"
                  :state="$v.paiementPrix.iban_vendeur.$dirty ? !$v.paiementPrix.iban_vendeur.$error : null"
                  :disabled="hasValidatedPaiementPrix"
                >
                </b-form-input>
              </div>
              <small class="text-danger" v-if="paiementPrix.iban_vendeur && $v.paiementPrix.iban_vendeur.$invalid">Veuillez saisir un iban valide</small>
            </div>
            <div class="col-12 col-lg-6">
              <div
                class="gavc-field mt-3"
                v-bind:class="{ 'is-error': $v.paiementPrix.iban_acquereur.$dirty ? $v.paiementPrix.iban_acquereur.$invalid : null  }">
                <label class="gavc-label" for="paiementPrix-iban_acquereur-group">
                  Iban de l'acquéreur
                  <span class="gavc-required"> * </span>
                </label>

                <b-form-input
                  v-model.lazy="maskedIbanAcquereur" type="text"
                  v-mask="'NNNN NNNN NNNN NNNN NNNN NNNN NNN'"
                  maxlength="50"
                  placeholder="Iban"
                  class="gavc-bootstrap-input"
                  id="iban-input"
                  @change="modifyPaiementPrix"
                  @input="$v.paiementPrix.iban_acquereur.$touch()"
                  :state="$v.paiementPrix.iban_acquereur.$dirty ? !$v.paiementPrix.iban_acquereur.$error : null"
                  :disabled="hasValidatedPaiementPrix"
                >
                </b-form-input>
              </div>
              <small class="text-danger" v-if="paiementPrix.iban_acquereur && $v.paiementPrix.iban_acquereur.$invalid">Veuillez saisir un iban valide</small>
            </div>
          </div>


          <p class="mt-4">
            Documents à charger : <strong>Rib du vendeur et Rib de l'acquéreur</strong>
          </p>

          <auditItemBothUploadable
            :userConnectedProfile="userConnectedProfile"
            @document-deleted="deleteAuditItem($event, 'paiementPrix')"
            @document-download="downloadAuditDocument($event, 'paiementPrix')"
            @document-uploaded="createAuditDocument($event, 'RIB_VENDEUR_ACQUEREUR', paiementPrix.id, 'paiementPrix')"
            @item-validated="validateAuditItem($event, 'paiementPrix', true)"
            @item-unvalidated="validateAuditItem($event, 'paiementPrix', false)"
            :items="filterDocuments('RIB_VENDEUR_ACQUEREUR')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'RIB_VENDEUR_ACQUEREUR', paiementPrix.id, 'paiementPrix')"
            type="RIB_VENDEUR_ACQUEREUR"
          />

        </div>

        <div v-if="paiementPrix.is_paye_one_shot">
          <div class="row mt-3">
            <div class="col-12 col-lg-6">
              <p class="gavc-label">
                Le paiement en <strong>espèces</strong> est-il une des modalités de versement du prix ?
                <span class="gavc-required"> * </span>
                <small>(montant maxium de 1000€)</small>
              </p>
              <div class="gavc-field">
                <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                  <div class="gavc-radio-icon">
                    <input :value="true" type="radio" id="paiementPrix.is_especes-input-1"
                           name="paiementPrix.is_especes-input-1" v-model="paiementPrix.is_especes"
                           :disabled="hasValidatedPaiementPrix">
                    <label class="gavc-label" for="paiementPrix.is_especes-input-1">
                      Oui
                    </label>
                  </div>
                  <div class="gavc-radio-icon">
                    <input :value="false" type="radio" id="paiementPrix.is_especes-input-2"
                           name="paiementPrix.is_especes-input-2" v-model="paiementPrix.is_especes"
                           :disabled="hasValidatedPaiementPrix">
                    <label class="gavc-label" for="paiementPrix.is_especes-input-2">
                      Non
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="paiementPrix.is_especes">
                <div
                   class="gavc-field mt-2"
                   v-bind:class="{ 'is-error': $v.paiementPrix.montant_especes.$dirty ? $v.paiementPrix.montant_especes.$error : null  }">
                   <label class="gavc-label" for="paiementPrix.montant_especes-input">
                       Montant
                   </label>
                  <div class="gavc-field gavc-field--icon">
                    <numberMoneyInput
                      id="paiementPrix.montant_especes-input"
                      :maxLength="'10'"
                      :maskedOptions="maskedMontantOptions"
                      :value="paiementPrix.montant_especes"
                      @value-changed="paiementPrix.montant_especes = $event"
                      @input="$v.paiementPrix.montant_especes.$touch()"
                      :disabled="hasValidatedPaiementPrix"
                    />
                    <i class="gavc-icon gavc-icon-euro" ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <p class="gavc-label">
                Le paiement par <strong>chèque</strong> est-il une des modalités de versement du prix ?
                <span class="gavc-required"> * </span>
              </p>
              <div class="gavc-field">
                <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                  <div class="gavc-radio-icon">
                    <input :value="true" type="radio" id="paiementPrix.is_cheques-input-1"
                           name="paiementPrix.is_cheques-input-1" v-model="paiementPrix.is_cheques"
                           :disabled="hasValidatedPaiementPrix">
                    <label class="gavc-label" for="paiementPrix.is_cheques-input-1">
                      Oui
                    </label>
                  </div>
                  <div class="gavc-radio-icon">
                    <input :value="false" type="radio" id="paiementPrix.is_cheques-input-2"
                           name="paiementPrix.is_cheques-input-2" v-model="paiementPrix.is_cheques"
                           :disabled="hasValidatedPaiementPrix">
                    <label class="gavc-label" for="paiementPrix.is_cheques-input-2">
                      Non
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="paiementPrix.is_cheques">
                <div
                   class="gavc-field mt-2"
                   v-bind:class="{ 'is-error': $v.paiementPrix.montant_cheques.$dirty ? $v.paiementPrix.montant_cheques.$error : null  }">
                   <label class="gavc-label" for="paiementPrix.montant_cheques-input">
                       Montant
                   </label>
                  <div class="gavc-field gavc-field--icon">
                    <numberMoneyInput
                      id="paiementPrix.montant_cheques-input"
                      :maxLength="'10'"
                      :maskedOptions="maskedMontantOptions"
                      :value="paiementPrix.montant_cheques"
                      @value-changed="paiementPrix.montant_cheques = $event"
                      @input="$v.paiementPrix.montant_cheques.$touch()"
                      :disabled="hasValidatedPaiementPrix"
                    />
                    <i class="gavc-icon gavc-icon-euro" ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div class="row mt-4">
          <div class="col-12 col-lg-6">
            <p class="gavc-label">
              Le paiement par <strong>chèque de banque</strong> est-il une des modalités de versement du prix ?
              <span class="gavc-required"> * </span>
            </p>
            <div class="gavc-field">
              <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                <div class="gavc-radio-icon">
                  <input :value="true" type="radio" id="paiementPrix.is_cheque_banque-input-1"
                         name="paiementPrix.is_cheque_banque-input-1" v-model="paiementPrix.is_cheque_banque"
                         :disabled="hasValidatedPaiementPrix">
                  <label class="gavc-label" for="paiementPrix.is_cheque_banque-input-1">
                    Oui
                  </label>
                </div>
                <div class="gavc-radio-icon">
                  <input :value="false" type="radio" id="paiementPrix.is_cheque_banque-input-2"
                         name="paiementPrix.is_cheque_banque-input-2" v-model="paiementPrix.is_cheque_banque"
                         :disabled="hasValidatedPaiementPrix">
                  <label class="gavc-label" for="paiementPrix.is_cheque_banque-input-2">
                    Non
                  </label>
                </div>
              </div>
            </div>
            <div v-if="paiementPrix.is_cheque_banque">
              <div
                 class="gavc-field"
                 v-bind:class="{ 'is-error': $v.paiementPrix.montant_cheque_banque.$dirty ? $v.paiementPrix.montant_cheque_banque.$error : null  }">
                 <label class="gavc-label" for="paiementPrix.montant_cheque_banque-input">
                     Montant
                 </label>
                <div class="gavc-field gavc-field--icon">
                  <numberMoneyInput
                    id="paiementPrix.montant_cheque_banque-input"
                    :maxLength="'10'"
                    :maskedOptions="maskedMontantOptions"
                    :value="paiementPrix.montant_cheque_banque"
                    @value-changed="paiementPrix.montant_cheque_banque = $event"
                    @input="$v.paiementPrix.montant_cheque_banque.$touch()"
                    :disabled="hasValidatedPaiementPrix"
                  />
                  <i class="gavc-icon gavc-icon-euro" ></i>
                </div>
              </div>
              <p class="mt-4">
                Documents à charger (si disponible) : <strong>Copie du chèque de banque</strong>
              </p>

              <auditItemBothUploadable
                :userConnectedProfile="userConnectedProfile"
                @document-deleted="deleteAuditItem($event, 'paiementPrix')"
                @document-download="downloadAuditDocument($event, 'paiementPrix')"
                @document-uploaded="createAuditDocument($event, 'CHEQUE_BANQUE', paiementPrix.id, 'paiementPrix')"
                @item-validated="validateAuditItem($event, 'paiementPrix', true)"
                @item-unvalidated="validateAuditItem($event, 'paiementPrix', false)"
                :items="filterDocuments('CHEQUE_BANQUE')"
                @justificatif-added="createAuditItemWithoutDocument($event, 'CHEQUE_BANQUE', paiementPrix.id, 'paiementPrix')"
                type="CHEQUE_BANQUE"
              />
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <p class="gavc-label">
              Le paiement par <strong>cryptomonnaies</strong> est-il une des modalités de versement du prix ?
              <span class="gavc-required"> * </span>
            </p>
            <div class="gavc-field">
              <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                <div class="gavc-radio-icon">
                  <input :value="true" type="radio" id="paiementPrix.is_cryptos-input-1"
                         name="paiementPrix.is_cryptos-input-1" v-model="paiementPrix.is_cryptos"
                         :disabled="hasValidatedPaiementPrix">
                  <label class="gavc-label" for="paiementPrix.is_cryptos-input-1">
                    Oui
                  </label>
                </div>
                <div class="gavc-radio-icon">
                  <input :value="false" type="radio" id="paiementPrix.is_cryptos-input-2"
                         name="paiementPrix.is_cryptos-input-2" v-model="paiementPrix.is_cryptos"
                         :disabled="hasValidatedPaiementPrix">
                  <label class="gavc-label" for="paiementPrix.is_cryptos-input-2">
                    Non
                  </label>
                </div>
              </div>
            </div>
            <div v-if="paiementPrix.is_cryptos">
              <div class="row mt-2">
                <div class="col-12 col-lg-6">
                  <div
                    class="gavc-field"
                    v-bind:class="{ 'is-error': $v.paiementPrix.monnaie_cryptos.$dirty ? $v.paiementPrix.monnaie_cryptos.$error : null  }">
                    <label class="gavc-label" for="paiementPrix-monnaie_cryptos-group">
                      Cryptomonnaie choisie
                      <span class="gavc-required"> * </span>
                    </label>
                    <input
                      class="gavc-input"
                      id="paiementPrix-monnaie_cryptos-group"
                      maxlength="250"
                      @input="$v.paiementPrix.monnaie_cryptos.$touch()"
                      type="text"
                      placeholder="Bitcoin, Ethereum, etc."
                      v-model="paiementPrix.monnaie_cryptos"
                      @change="modifyPaiementPrix"
                      :disabled="hasValidatedPaiementPrix"
                    >
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div
                     class="gavc-field "
                     v-bind:class="{ 'is-error': $v.paiementPrix.montant_cryptos.$dirty ? $v.paiementPrix.montant_cryptos.$error : null  }">
                     <label class="gavc-label" for="paiementPrix.montant_cryptos-input">
                         Montant
                     </label>
                    <div class="gavc-field gavc-field--icon">
                      <numberMoneyInput
                        id="paiementPrix.montant_cryptos-input"
                        :maxLength="'10'"
                        :maskedOptions="maskedMontantOptions"
                        :value="paiementPrix.montant_cryptos"
                        @value-changed="paiementPrix.montant_cryptos = $event"
                        @input="$v.paiementPrix.montant_cryptos.$touch()"
                        :disabled="hasValidatedPaiementPrix"
                      />
                      <i class="gavc-icon gavc-icon-euro" ></i>
                    </div>
                  </div>
                </div>
              </div>

              <p class="mt-4">
                Documents à charger (si disponible) : <strong>Tout élément de preuve</strong>
              </p>

              <auditItemBothUploadable
                :userConnectedProfile="userConnectedProfile"
                @document-deleted="deleteAuditItem($event, 'paiementPrix')"
                @document-download="downloadAuditDocument($event, 'paiementPrix')"
                @document-uploaded="createAuditDocument($event, 'CRYPTO_PREUVE', paiementPrix.id, 'paiementPrix')"
                @item-validated="validateAuditItem($event, 'paiementPrix', true)"
                @item-unvalidated="validateAuditItem($event, 'paiementPrix', false)"
                :items="filterDocuments('CRYPTO_PREUVE')"
                @justificatif-added="createAuditItemWithoutDocument($event, 'CRYPTO_PREUVE', paiementPrix.id, 'paiementPrix')"
                type="CRYPTO_PREUVE"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>




    <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.source-financement-collapse>
      Sources du financement
      <span class="when-opened float-right">
        <v-icon name="chevron-down" />
      </span>
      <span class="when-closed float-right">
        <v-icon name="chevron-right" />
      </span>
    </b-button>

    <b-collapse id="source-financement-collapse" role="tabpanel">
      <div class="row mt-3">
        <div class="col-12 col-lg-6">
          <p class="gavc-label">
            La reprise se fait-elle via un <strong>emprunt bancaire</strong>?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="paiementPrix.is_emprunt_bancaire-input-1"
                       name="paiementPrix.is_emprunt_bancaire-input-1" v-model="paiementPrix.is_emprunt_bancaire"
                       :disabled="hasValidatedPaiementPrix">
                <label class="gavc-label" for="paiementPrix.is_emprunt_bancaire-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="paiementPrix.is_emprunt_bancaire-input-2"
                       name="paiementPrix.is_emprunt_bancaire-input-2" v-model="paiementPrix.is_emprunt_bancaire"
                       :disabled="hasValidatedPaiementPrix">
                <label class="gavc-label" for="paiementPrix.is_emprunt_bancaire-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>
          <div v-if="paiementPrix.is_emprunt_bancaire">
            <div
               class="gavc-field "
               v-bind:class="{ 'is-error': $v.paiementPrix.montant_emprunt_bancaire.$dirty ? $v.paiementPrix.montant_emprunt_bancaire.$error : null  }">
               <label class="gavc-label" for="paiementPrix.montant_emprunt_bancaire-input">
                   Montant
               </label>
              <div class="gavc-field gavc-field--icon">
                <numberMoneyInput
                  id="paiementPrix.montant_emprunt_bancaire-input"
                  :maxLength="'10'"
                  :maskedOptions="maskedMontantOptions"
                  :value="paiementPrix.montant_emprunt_bancaire"
                  @value-changed="paiementPrix.montant_emprunt_bancaire = $event"
                  @input="$v.paiementPrix.montant_emprunt_bancaire.$touch()"
                  :disabled="hasValidatedPaiementPrix"
                />
                <i class="gavc-icon gavc-icon-euro" ></i>
              </div>
            </div>

            <div class="gavc-field">
              <label class="gavc-label" for="paiementPrix-banque_coordonnees_emprunt_bancaire-input">
                Banque, agence, adresse
              </label>
              <textarea
                class="gavc-textarea"
                id="elementsSociauxActiviteCedee-banque_coordonnees_emprunt_bancaire-input"
                maxlength="1500"
                rows="5"
                placeholder="Détailler la banque, l'agence et l'adresse"
                type="text"
                v-model="paiementPrix.banque_coordonnees_emprunt_bancaire"
                @change="modifyPaiementPrix"
                :disabled="hasValidatedPaiementPrix"
              >
              </textarea>
            </div>

            <p class="mt-4">
              Documents à charger (si disponible) : <strong>Contrat de prêt</strong>
            </p>

            <auditItemBothUploadable
              :userConnectedProfile="userConnectedProfile"
              @document-deleted="deleteAuditItem($event, 'paiementPrix')"
              @document-download="downloadAuditDocument($event, 'paiementPrix')"
              @document-uploaded="createAuditDocument($event, 'CONTRAT_PRET_EMPRUNT', paiementPrix.id, 'paiementPrix')"
              @item-validated="validateAuditItem($event, 'paiementPrix', true)"
              @item-unvalidated="validateAuditItem($event, 'paiementPrix', false)"
              :items="filterDocuments('CONTRAT_PRET_EMPRUNT')"
              @justificatif-added="createAuditItemWithoutDocument($event, 'CONTRAT_PRET_EMPRUNT', paiementPrix.id, 'paiementPrix')"
              type="CONTRAT_PRET_EMPRUNT"
            />

          </div>
        </div>
        <div class="col-12 col-lg-6">
          <p class="gavc-label">
            La reprise se fait-elle sur les <strong>deniers personnels</strong> de l'acquéreur ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="paiementPrix.is_deniers_personnels-input-1"
                       name="paiementPrix.is_deniers_personnels-input-1" v-model="paiementPrix.is_deniers_personnels"
                       :disabled="hasValidatedPaiementPrix">
                <label class="gavc-label" for="paiementPrix.is_deniers_personnels-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="paiementPrix.is_deniers_personnels-input-2"
                       name="paiementPrix.is_deniers_personnels-input-2" v-model="paiementPrix.is_deniers_personnels"
                       :disabled="hasValidatedPaiementPrix">
                <label class="gavc-label" for="paiementPrix.is_deniers_personnels-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>

          <div v-if="paiementPrix.is_deniers_personnels">
            <div
               class="gavc-field "
               v-bind:class="{ 'is-error': $v.paiementPrix.montant_deniers_personnels.$dirty ? $v.paiementPrix.montant_deniers_personnels.$error : null  }">
               <label class="gavc-label" for="paiementPrix.montant_deniers_personnels-input">
                   Montant
               </label>
              <div class="gavc-field gavc-field--icon">
                <numberMoneyInput
                  id="paiementPrix.montant_deniers_personnels-input"
                  :maxLength="'10'"
                  :maskedOptions="maskedMontantOptions"
                  :value="paiementPrix.montant_deniers_personnels"
                  @value-changed="paiementPrix.montant_deniers_personnels = $event"
                  @input="$v.paiementPrix.montant_deniers_personnels.$touch()"
                  :disabled="hasValidatedPaiementPrix"
                />
                <i class="gavc-icon gavc-icon-euro" ></i>
              </div>
            </div>

            <p class="mt-4">
              Documents à charger (si disponible) : <strong>Copie de l'extrait bancaire</strong>
            </p>

            <auditItemBothUploadable
              :userConnectedProfile="userConnectedProfile"
              @document-deleted="deleteAuditItem($event, 'paiementPrix')"
              @document-download="downloadAuditDocument($event, 'paiementPrix')"
              @document-uploaded="createAuditDocument($event, 'COPIE_EXTRAIT_BANCAIRE', paiementPrix.id, 'paiementPrix')"
              @item-validated="validateAuditItem($event, 'paiementPrix', true)"
              @item-unvalidated="validateAuditItem($event, 'paiementPrix', false)"
              :items="filterDocuments('COPIE_EXTRAIT_BANCAIRE')"
              @justificatif-added="createAuditItemWithoutDocument($event, 'COPIE_EXTRAIT_BANCAIRE', paiementPrix.id, 'paiementPrix')"
              type="COPIE_EXTRAIT_BANCAIRE"
            />

          </div>
        </div>
      </div>
    </b-collapse>




    <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.securisation-paiement-collapse>
      Sécurisation du paiement
      <span class="when-opened float-right">
        <v-icon name="chevron-down" />
      </span>
      <span class="when-closed float-right">
        <v-icon name="chevron-right" />
      </span>
    </b-button>

    <b-collapse id="securisation-paiement-collapse" role="tabpanel">
      <div class="row mt-3">
        <div class="col-12 col-lg-6">
          <p class="gavc-label">
            La cession est-elle conditionnée au paiement du premier versement dû ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="paiementPrix.is_cession_conditionnee_premier_versement-input-1"
                       name="paiementPrix.is_cession_conditionnee_premier_versement-input-1"
                       :disabled="hasValidatedPaiementPrix"
                       v-model="paiementPrix.is_cession_conditionnee_premier_versement"
                >
                <label class="gavc-label" for="paiementPrix.is_cession_conditionnee_premier_versement-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="paiementPrix.is_cession_conditionnee_premier_versement-input-2"
                       name="paiementPrix.is_cession_conditionnee_premier_versement-input-2"
                       :disabled="hasValidatedPaiementPrix"
                       v-model="paiementPrix.is_cession_conditionnee_premier_versement"
                >
                <label class="gavc-label" for="paiementPrix.is_cession_conditionnee_premier_versement-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <p class="gavc-label">
            Souhaitez-vous résilier le contrat et restituer les sommes en cas de retard de paiement ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="paiementPrix.is_resiliation_retard-input-1"
                       name="paiementPrix.is_resiliation_retard-input-1" v-model="paiementPrix.is_resiliation_retard"
                       :disabled="hasValidatedPaiementPrix">
                <label class="gavc-label" for="paiementPrix.is_resiliation_retard-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="paiementPrix.is_resiliation_retard-input-2"
                       name="paiementPrix.is_resiliation_retard-input-2" v-model="paiementPrix.is_resiliation_retard"
                       :disabled="hasValidatedPaiementPrix">
                <label class="gavc-label" for="paiementPrix.is_resiliation_retard-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>

          <div v-if="paiementPrix.is_resiliation_retard">
            <div
               class="gavc-field mt-4"
               v-bind:class="{ 'is-error': $v.paiementPrix.resiliation_retard_nb_jours.$dirty ? $v.paiementPrix.resiliation_retard_nb_jours.$error : null  }">
               <label class="gavc-label" for="paiementPrix.resiliation_retard_nb_jours-input">
                  Nombre de jours calendaires de retard de paiement d'une échéance maximum avant résiliation
               </label>
              <div class="gavc-field">
                <input class="gavc-input"
                   @input="$v.paiementPrix.resiliation_retard_nb_jours.$touch()"
                   id="paiementPrix.resiliation_retard_nb_jours-input"
                   placeholder="0"
                   type="number"
                   v-model="paiementPrix.resiliation_retard_nb_jours"
                   @change="modifyPaiementPrix"
                   :disabled="hasValidatedPaiementPrix"
                  >
              </div>
            </div>
          </div>
        </div>
      </div>


      <p class="gavc-label mt-3">
        Souhaitez-vous ajouter une indemnité en cas de retard de paiement ?
        <span class="gavc-required"> * </span>
      </p>
      <div class="gavc-field">
        <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
          <div class="gavc-radio-icon">
            <input :value="true" type="radio" id="paiementPrix.is_penalites_retard-input-1"
                   name="paiementPrix.is_penalites_retard-input-1" v-model="paiementPrix.is_penalites_retard"
                   :disabled="hasValidatedPaiementPrix">
            <label class="gavc-label" for="paiementPrix.is_penalites_retard-input-1">
              Oui
            </label>
          </div>
          <div class="gavc-radio-icon">
            <input :value="false" type="radio" id="paiementPrix.is_penalites_retard-input-2"
                   name="paiementPrix.is_penalites_retard-input-2" v-model="paiementPrix.is_penalites_retard"
                   :disabled="hasValidatedPaiementPrix">
            <label class="gavc-label" for="paiementPrix.is_penalites_retard-input-2">
              Non
            </label>
          </div>
        </div>
      </div>

      <div v-if="paiementPrix.is_penalites_retard">
        <div class="row mt-3">
          <div class="col-12 col-lg-6">
            <div
               class="gavc-field"
               v-bind:class="{ 'is-error': $v.paiementPrix.penalites_retard_nb_jours.$dirty ? $v.paiementPrix.penalites_retard_nb_jours.$error : null  }">
               <label class="gavc-label" for="paiementPrix.penalites_retard_nb_jours-input">
                  Nombre de jours calendaires de retard de paiement avant versement d'une indemnité
               </label>
              <div class="gavc-field">
                <input class="gavc-input"
                   @input="$v.paiementPrix.penalites_retard_nb_jours.$touch()"
                   id="paiementPrix.penalites_retard_nb_jours-input"
                   placeholder="0"
                   type="number"
                   v-model="paiementPrix.penalites_retard_nb_jours"
                   @change="modifyPaiementPrix"
                   :disabled="hasValidatedPaiementPrix"
                  >
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div
               class="gavc-field"
               v-bind:class="{ 'is-error': $v.paiementPrix.penalites_retard_montant.$dirty ? $v.paiementPrix.penalites_retard_montant.$error : null  }">
               <label class="gavc-label" for="paiementPrix.penalites_retard_montant-input">
                   Montant de l'indemnité
               </label>
              <div class="gavc-field gavc-field--icon">
                <numberMoneyInput
                  id="paiementPrix.penalites_retard_montant-input"
                  :maxLength="'10'"
                  :maskedOptions="maskedMontantOptions"
                  :value="paiementPrix.penalites_retard_montant"
                  @value-changed="paiementPrix.penalites_retard_montant = $event"
                  @input="$v.paiementPrix.penalites_retard_montant.$touch()"
                  :disabled="hasValidatedPaiementPrix"
                />
                <i class="gavc-icon gavc-icon-euro" ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <div v-if="(!paiementPrix.has_cedant_validated && userGroupContains(['CEDANT'])) || (!paiementPrix.has_repreneur_validated && userGroupContains(['REPRENEUR']))" class="row mt-4">
      <div class="col-12 text-right">
        <validationSectionButton
          @modify="modifyPaiementPrix"
          @validate="validatePaiementPrix(true)"
        />
      </div>
    </div>
    <div v-else-if="(paiementPrix.has_cedant_validated && userGroupContains(['CEDANT'])) || (paiementPrix.has_repreneur_validated && userGroupContains(['REPRENEUR']))" class="row mt-4">
      <div class="col-12 text-right">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="validatePaiementPrix(false)"
        >
            Invalider cette étape
        </button>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="paiementPrix.has_cedant_validated"
          text="Le cédant a validé toute la section"
        />
      </div>
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="paiementPrix.has_repreneur_validated"
          text="Le repreneur a validé toute la section"
        />
      </div>
    </div>

    <div class="mt-4 text-center" v-if="paiementPrix.has_repreneur_validated && paiementPrix.has_cedant_validated">
      <button
        class="gavc-btn"
        @click="$emit('next-step')">
            Étape validée - Accéder à l'étape suivante
      </button>
    </div>
  </div>
</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { integer, minLength, maxLength, minValue, maxValue, numeric, required, requiredIf } from 'vuelidate/lib/validators'

import { APIConnected } from '@/api/APIConnected'
import auditItemsMixins from '@/mixins/auditItemsMixins'
import auditSectionInformationMixins from '@/mixins/auditSectionInformationMixins'
import renderMixins from '@/mixins/renderMixins'
import userMixins from '@/mixins/userMixins'
import uxMixins from '@/mixins/uxMixins'
import { isDate, isValidIban } from '@/validators/validators'
import { defaultMoneyMaskOptions } from "@/variables/masks/moneyMasks"

const apiConnectedService = new APIConnected()
const auditItemBothUploadable = () => import('@/components/audit/auditItemBothUploadable')
const hasValidatedMessage = () => import('@/components/audit/hasValidatedMessage')
const inputErrorValidationMessage = () => import('@/components/inputs/inputErrorValidationMessage')
const macroStepper = () => import('@/components/audit/macroStepper')
const numberMoneyInput = () => import('@/components/inputs/numberMoneyInput')
const validationCartouche = () => import('@/components/layout/validationCartouche')
const validationSectionButton = () => import('@/components/audit/validationSectionButton')

export default {
  name: 'PaiementPrix',
  components: {
    auditItemBothUploadable,
    hasValidatedMessage,
    inputErrorValidationMessage,
    macroStepper,
    numberMoneyInput,
    validationCartouche,
    validationSectionButton
  },
  mixins: [
    auditItemsMixins,
    auditSectionInformationMixins,
    renderMixins,
    userMixins,
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isGettingPaiementPrix: false,
      isPaiementPrixValide: false,

      paiementPrix: {
        audit: {
          cedant_comment: "",
          repreneur_comment: "",
          has_cedant_validated: false,
          has_repreneur_validated: false,
          candidature: {
            description: '',
            offre_cession: {
              company: {
                cedant: {
                  phone_number: '',
                  user: {
                    first_name: '',
                    last_name: '',
                    username: ''
                  }
                },
                name: '',
                siren: ''
              },
              price: '',
              title: '',

            },
            repreneur: {
              phone_number: '',
              user: {
                first_name: '',
                last_name: '',
                username: ''
              }
            },
            state: '3VALIDATED'
          },
        },
        has_cedant_validated: false,
        has_repreneur_validated: false,
        items: [],
        date_echeances: [],

        is_paye_one_shot: null,
        montant_total_one_shot: null,
        fractionne_type: "",
        montant_sommes_fixes: null,
        montant_initial_complete: null,
        montant_final_complete: null,
        montant_final_modalite_calcul: null,

        is_clause_interessement_performances_futures: null,
        clause_interessement_nb_months: null,
        clause_interessement_percent_ratio_comptable: null,
        clause_interessement_ratio_comptable_chosen: "",
        clause_interessement_liste_conditions: "",

        is_virement: false,
        montant_virement: null,
        iban_vendeur: "",
        iban_acquereur: "",

        is_especes: false,
        montant_especes: null,

        is_cheques: false,
        montant_cheques: null,

        is_cheque_banque: false,
        montant_cheque_banque: null,

        is_cryptos: false,
        monnaie_cryptos: null,
        montant_cryptos: null,

        is_emprunt_bancaire: false,
        montant_emprunt_bancaire: null,
        banque_coordonnees_emprunt_bancaire: "",
        is_deniers_personnels: false,
        montant_deniers_personnels: null,

        is_cession_conditionnee_premier_versement: false,
        is_resiliation_retard: false,
        resiliation_retard_nb_jours: null,
        is_penalites_retard: false,
        penalites_retard_nb_jours: null,
        penalites_retard_montant: null,
      },

      maskedIbanVendeur: "",
      maskedIbanAcquereur: "",

      maskedMontantOptions: defaultMoneyMaskOptions,

      newDateEcheancePrixSommesFixesDate: '',

      newDateEcheancePrixSommesVariablesDate: '',
      newDateEcheancePrixSommesVariablesMontant: ''
    }
  },
  computed: {
    hasValidatedPaiementPrix () {
      return (this.userGroupContains(['CEDANT']) && this.paiementPrix.has_cedant_validated) ||
             (this.userGroupContains(['REPRENEUR']) && this.paiementPrix.has_repreneur_validated)
    },
    token () {
      return this.$store.state.auth.token
    },
    isInvalidPaiementPrix () {
      if (this.$v.paiementPrix.$invalid) {
        return true
      }
      return false
    },
    userConnectedProfile () {
      return JSON.parse(this.$store.getters.getUserGroups)[0].name
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.scrollToTop()
    this.getPaiementPrix()
  },
  watch: {
    'paiementPrix.is_paye_one_shot' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.fractionne_type' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_clause_interessement_performances_futures' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_virement' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_especes' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_cheques' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_cheque_banque' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_cryptos' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_emprunt_bancaire' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_deniers_personnels' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_cession_conditionnee_premier_versement' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_resiliation_retard' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },
    'paiementPrix.is_penalites_retard' () {
      if (!this.isGettingPaiementPrix) {
        this.modifyPaiementPrix()
      }
    },

    maskedIbanVendeur(value) {
      if (value) {
        this.paiementPrix.iban_vendeur = value.replace(/ /g,'')
      }
    },
    maskedIbanAcquereur(value) {
      if (value) {
        this.paiementPrix.iban_acquereur = value.replace(/ /g,'')
      }
    },
  },

  validations: {
    paiementPrix: {
      is_paye_one_shot: {
        required,
      },
      montant_total_one_shot: {
        required: requiredIf(function() {
          return this.paiementPrix.is_paye_one_shot
        }),
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },
      montant_sommes_fixes: {
        required: requiredIf(function() {
          return this.paiementPrix.fractionne_type === 'SOMMES_FIXES'
        }),
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },
      clause_interessement_nb_months: {
        required: requiredIf(function() {
          return this.paiementPrix.is_clause_interessement_performances_futures
        }),
        integer,
        minValue: minValue(0),
        maxValue: maxValue(1000)
      },
      clause_interessement_percent_ratio_comptable: {
        required: requiredIf(function() {
          return this.paiementPrix.is_clause_interessement_performances_futures
        }),
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(1000)
      },
      clause_interessement_ratio_comptable_chosen: {
        required: requiredIf(function() {
          return this.paiementPrix.is_clause_interessement_performances_futures
        }),
        minLength: minLength(2),
        maxLength: maxLength(1000)
      },
      montant_virement: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },
      iban_vendeur: {
        minLength: minLength(2),
        maxLength: maxLength(50),
        validIban(value) {
          return isValidIban(value)
        }
      },
      iban_acquereur: {
        minLength: minLength(2),
        maxLength: maxLength(50),
        validIban(value) {
          return isValidIban(value)
        }
      },
      montant_especes: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },

      montant_cheques: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },

      montant_cheque_banque: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },

      monnaie_cryptos: {
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      montant_cryptos: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },

      montant_emprunt_bancaire: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },
      banque_coordonnees_emprunt_bancaire: {
        minLength: minLength(2),
        maxLength: maxLength(50),
      },

      montant_deniers_personnels: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },

      resiliation_retard_nb_jours: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },

      penalites_retard_nb_jours: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },
      penalites_retard_montant: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },

      clause_garantie: {
        minLength: minLength(2),
        maxLength: maxLength(50),
      },

    },
    newDateEcheancePrixSommesFixesDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    newDateEcheancePrixSommesVariablesDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    newDateEcheancePrixSommesVariablesMontant: {
      required,
      numeric,
      minValue: minValue(0),
      maxValue: maxValue(100000000)
    }
  },
  methods: {
    addDateEcheancePrixSommesFixes () {
      const data = {
        'section': this.paiementPrix.id,
        'date': this.newDateEcheancePrixSommesFixesDate,
      }
      this.createAuditSectionInformation(data, 'dateEcheancePrixSommesFixes')
    },
    addDateEcheancePrixSommesVariables () {
      const data = {
        'section': this.paiementPrix.id,
        'date': this.newDateEcheancePrixSommesVariablesDate,
        'montant': this.newDateEcheancePrixSommesVariablesMontant,
      }
      this.createAuditSectionInformation(data, 'dateEcheancePrixSommesVariables')
    },
    informationAdded(data, sectionInformation) {
      if(sectionInformation === 'dateEcheancePrixSommesFixes') {
        this.paiementPrix.date_echeances_sommes_fixes.push(data)
        this.newDateEcheancePrixSommesFixesDate = ''
      }
      if(sectionInformation === 'dateEcheancePrixSommesVariables') {
        this.paiementPrix.date_echeances_sommes_variables.push(data)
        this.newDateEcheancePrixSommesVariablesDate = ''
        this.newDateEcheancePrixSommesVariablesMontant = ''

      }
    },
    informationDeleted(informationId, sectionInformation) {
      if(sectionInformation === 'dateEcheancePrixSommesFixes') {
        this.paiementPrix.date_echeances_sommes_fixes = this.paiementPrix.date_echeances_sommes_fixes.filter(item => item.id !== informationId);
      }
      if(sectionInformation === 'dateEcheancePrixSommesVariables') {
        this.paiementPrix.date_echeances_sommes_variables = this.paiementPrix.date_echeances_sommes_variables.filter(item => item.id !== informationId);
      }
    },

    filterDocuments (type, uploader) {
       if (this.uploader) {
         return this.paiementPrix.items.filter(item => item.type === type && item.uploader === uploader)
       }
       return this.paiementPrix.items.filter(item => item.type === type)
    },
    documentModified () {
      this.getPaiementPrix()
    },
    getPaiementPrix () {
      this.isGettingPaiementPrix = true
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAuditSection(this.token, 'paiementPrix', this.$route.params.paiementPrixId)
      .then((result) => {
        this.paiementPrix = result.data
        this.afterPaiementPrixFetching()
        if (this.$v.paiementPrix.$invalid) {
          this.isPaiementPrixValide = false
        }
        else {
          this.isPaiementPrixValide = true
        }
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('PaiementPrix getPaiementPrix API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isGettingPaiementPrix = false
      })
    },

    modifyPaiementPrix () {
      if(this.paiementPrix.iban_vendeur && this.$v.paiementPrix.iban_vendeur.$invalid ||
         this.paiementPrix.iban_acquereur && this.$v.paiementPrix.iban_acquereur.$invalid) {
        return
      }

      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyAuditSection(this.token, 'paiementPrix', this.$route.params.paiementPrixId, this.paiementPrix)
      .then((result) => {
        this.paiementPrix = result.data
        this.afterPaiementPrixFetching()
        if (this.$v.paiementPrix.$invalid) {
          this.isPaiementPrixValide = false
        }
        else {
          this.isPaiementPrixValide = true
        }
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast("Vos informations transmises dans l'audit ont bien été mises à jour", {
          title: 'Informations mises à jour',
          id: 'notification-toast',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('PaiementPrix modifyAuditSection API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    afterPaiementPrixFetching () {
      this.maskedIbanVendeur = this.paiementPrix.iban_vendeur
      this.maskedIbanAcquereur = this.paiementPrix.iban_acquereur
      const montants = [
        "montant_total_one_shot", "montant_sommes_fixes", "montant_virement", "montant_especes",
        "montant_cheques", "montant_cheque_banque", "montant_cryptos", "montant_emprunt_bancaire",
        "montant_deniers_personnels", "penalites_retard_montant"
      ]
      montants.forEach((item) => {
        if (this.paiementPrix[item]) {
          this.paiementPrix[item] = Number(this.paiementPrix[item])
        }
      })
    },
    validatePaiementPrix (validationStatus) {
      const data = this.paiementPrix
      this.$store.commit('loadFromAPI')
      if (this.userGroupContains(['CEDANT'])) {
        data['has_cedant_validated'] = validationStatus
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        data['has_repreneur_validated'] = validationStatus
      }
      apiConnectedService.modifyAuditSection(this.token, 'paiementPrix', this.$route.params.paiementPrixId, data)
      .then((result) => {
        this.paiementPrix = result.data
        if (validationStatus) {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Félicitations, vous avez bien validé cette partie", {
            title: 'Section validée',
            id: 'notification-toast',
            variant: 'success',
            solid: true
          })
        }
        else {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Vous avez bien invalidé cette partie, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
            id: 'notification-toast',
            title: 'Etape invalidée',
            variant: 'danger',
            solid: true
          })
        }
        this.scrollToTop()
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('PaiementPrix validatePaiementPrix API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },

    goToDashboard() {
      if (this.userGroupContains(['CEDANT'])) {
        this.$router.push('/cedant/tableau-de-bord')
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        this.$router.push('/repreneur/tableau-de-bord')
      }
    }
  }
}
</script>

<style>
.subsection-button,
.subsection-button:active,
.subsection-button:focus,
.subsection-button:hover,
.subsection-button:focus-visible {
  background-color: #f4ebe2 !important;
  border-bottom: 0.2px solid #33304c;
  border-left: None;
  border-radius: 0px;
  border-right: None;
  border-top: None;
  box-shadow: none;
  color: #33304c !important;
  font-size: 1.2rem;
  text-align: left;
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
  display: none;
}
</style>
